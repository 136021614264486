export type SwiperProps = {
	title: string
	items: {
		header: string
		subHeader: string
		description: string
		imageSrc: string
		imageAlt: string
	}[]
}

export function Swiper({ title, items }: SwiperProps) {
	return (
		<div className="container">
			<div className="w-full">
				<div className="flex items-baseline justify-between">
					<h2 className="mb-8 text-h2 uppercase tracking-tight text-foreground lg:text-h1">
						{title}
					</h2>
				</div>

				<div className="grid grid-cols-1">
					<div className="scrollbar-hide flex w-full snap-x snap-proximity gap-8 overflow-x-auto overscroll-x-none">
						{items.map((item) => {
							const key = `${item.header.split(' ').join('-').toLowerCase()}-${item.subHeader.split(' ').join('-').toLowerCase()}`

							return (
								<div
									key={key}
									className="block h-full w-[75%] shrink-0 snap-start lg:w-[29%]"
								>
									<div className="group w-full">
										<div className="mb-4 aspect-[3/4] w-full overflow-hidden">
											<div className="w-full">
												<img
													alt={item.imageAlt}
													src={item.imageSrc}
													className="h-full w-full object-cover"
												/>
											</div>
										</div>
										<p className="mb-2 text-xs uppercase text-muted-foreground lg:text-sm">
											{item.subHeader}
										</p>
										<h3 className="mb-2 text-h5 uppercase text-foreground lg:text-h4">
											{item.header}
										</h3>
										<p className="text-base font-light text-muted-foreground lg:text-lg">
											{item.description}
										</p>
									</div>
								</div>
							)
						})}
					</div>
				</div>
			</div>
		</div>
	)
}
