import { json, type MetaFunction } from '@remix-run/node'
import { useLoaderData } from '@remix-run/react'
import { Feature } from '#app/components/feature.tsx'
import { HorizontalRule } from '#app/components/horizontal-rule.tsx'
import { HotelSwiper } from '#app/components/swiper-hotels.tsx'
import { Swiper } from '#app/components/swiper.tsx'
import { getRandomHotels } from '#app/utils/mb/hotels.server.ts'
import { ACTIVITIES, FOOD_DRINKS } from '#app/utils/mb/swiper-data.ts'

export const meta: MetaFunction = () => [{ title: 'Maldives Booking' }]

export async function loader() {
	const hotels = await getRandomHotels()

	return json({ hotels })
}

export default function IndexRoute() {
	const { hotels } = useLoaderData<typeof loader>()

	return (
		<main className="grid h-full place-items-center">
			<Landing />
			{/* QUOTE */}
			{/* <HorizontalRule /> */}

			{/* HOTELS */}
			{hotels.length > 0 && (
				<>
					<HotelSwiper title="Where to stay" hotels={hotels} />
					<HorizontalRule />
				</>
			)}

			{/* ICP 1 */}
			<Feature
				title="For romantics"
				items={[
					{
						title: 'Honeymoon packages',
						description:
							'Celebrate your love in Maldives, where your dream honeymoon comes to life in our stunning tropical paradise.',
						imageSrc: '/img/landing/romantics-2.jpg',
					},
				]}
			/>
			<HorizontalRule />

			{/* ACTIVITIES */}
			<Swiper title="Things to do" items={ACTIVITIES} />
			<HorizontalRule />

			{/* ICP 2 */}
			<Feature
				title="For explorers"
				items={[
					{
						title: 'Guesthouse packages',
						description:
							'Embark on a Maldives adventure, staying in cozy guesthouses while enjoying surfing, parasailing, and thrilling water sports, all enriched by local culture and hospitality.',
						imageSrc: '/img/landing/adventurer-1.jpg',
					},
				]}
			/>
			<HorizontalRule />

			{/* FOOD */}
			<Swiper title="Food and Drink" items={FOOD_DRINKS} />
			<HorizontalRule />
		</main>
	)
}

function Landing() {
	return (
		<div className="container mb-8 h-[calc(100vh-80px)] pb-8">
			<div className="relative h-full bg-white">
				<img
					src="/img/landing/hero-1.jpg"
					alt=""
					className="h-full w-full object-cover"
				/>
				<div className="absolute inset-0 flex flex-col items-center justify-between bg-gradient-to-br from-black/15 from-10% via-black/15 via-20% to-70% p-8">
					<div className="flex w-full flex-col items-start justify-center">
						<h1 className="w-full text-balance text-center text-5xl font-semibold uppercase tracking-tight text-white sm:text-left sm:text-7xl">
							Sun. Sip. Splash.
						</h1>
						<p className="mt-2 w-full text-pretty text-center text-lg font-medium uppercase text-white sm:text-left sm:text-xl/8">
							Experience the Maldives like never before.
						</p>
					</div>

					<div className="mt-10 flex w-full items-center justify-end">
						{/* <div className="flex items-center justify-center gap-x-6 rounded-md bg-white">
							<Link to="#hotels-list" className={cn(buttonVariants())}>
								Browse & Book <Icon name="chevron-down" />
							</Link>
						</div> */}
					</div>
				</div>
			</div>
		</div>
	)
}
