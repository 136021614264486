type FeatureItem = {
	title: string
	description: string
	imageSrc: string
	imageAlt?: string
}

type FeatureProps = {
	title: string
	items: [FeatureItem]
}

export function Feature({ title, items }: FeatureProps) {
	const item = items[0]
	return (
		<div className="container">
			<div className="w-full">
				<div className="flex items-baseline justify-between lg:hidden">
					<p className="mb-8 text-h2 uppercase tracking-tight text-gray-900 lg:text-h1">
						{title}
					</p>
				</div>

				<div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
					<div className="col-span-1">
						<div>
							<div className="hidden items-baseline justify-between lg:flex">
								<h2 className="mb-8 text-h2 uppercase tracking-tight text-gray-900 lg:text-h1">
									{title}
								</h2>
							</div>

							<h3 className="mb-4 flex items-center align-middle text-h5 uppercase text-gray-900 lg:text-h4">
								{item.title}
							</h3>
							<p className="text-base font-light text-gray-500 lg:text-lg">
								{item.description}
							</p>
						</div>
						<div className="hidden grid-cols-1 lg:grid">
							{/* DESKTOP CTA */}
						</div>
					</div>
					<div className="col-span-2 mb-2 aspect-video w-full overflow-hidden">
						<div className="w-full">
							<img
								alt={item.imageAlt}
								src={item.imageSrc}
								className="h-full w-full object-cover"
							/>
						</div>
					</div>
				</div>

				<div className="grid grid-cols-1">{/* MOBILE CTA */}</div>
			</div>
		</div>
	)
}
