import { type SwiperProps } from '#app/components/swiper.tsx'

export const ACTIVITIES: SwiperProps['items'] = [
	{
		header: 'Snorkelling & Diving',
		subHeader: 'Underwater',
		description:
			'Discover the vibrant underwater paradise of the Maldives, where snorkeling and diving reveal a kaleidoscope of marine life in crystal-clear turquoise waters.',
		imageSrc: '/img/landing/act-1.jpg',
		imageAlt: 'Woman swimming underwater in the Maldives.',
	},
	{
		header: 'Spa & Wellness',
		subHeader: 'Relaxation',
		description:
			'Indulge in ultimate relaxation with luxurious spa and wellness treatments in the Maldives, where serene settings and expert care rejuvenate your mind, body, and soul.',
		imageSrc: '/img/landing/act-5.jpg',
		imageAlt: "A spa in the Maldives' tropical setting.",
	},
	{
		header: 'Island Hopping',
		subHeader: 'Excursions',
		description:
			'Embark on an unforgettable island-hopping journey in the Maldives, exploring pristine beaches, local culture, and hidden tropical gems.',
		imageSrc: '/img/landing/act-3.jpg',
		imageAlt: 'A boat going past a Maldivian island.',
	},
	{
		header: 'Water Sports',
		subHeader: 'Sports',
		description:
			'Dive into adventure with thrilling water sports in the Maldives, from jet skiing and parasailing to kayaking and paddleboarding across stunning turquoise lagoons.',
		imageSrc: '/img/landing/act-2.jpg',
		imageAlt: "People kayaking in the Maldives' turquoise waters.",
	},
	{
		header: 'Yoga & Meditation',
		subHeader: 'Relaxation',
		description:
			'Find your inner peace with rejuvenating yoga and meditation sessions in the serene paradise of the Maldives, surrounded by tranquil ocean views and gentle breezes.',
		imageSrc: '/img/landing/act-4.jpg',
		imageAlt: 'Model in a yoga pose.',
	},
	{
		header: 'Fishing',
		subHeader: 'Recreational',
		description:
			"Experience the thrill of fishing in the Maldives, from traditional handline techniques to exciting deep-sea adventures in the world's most abundant waters",
		imageSrc: '/img/landing/act-6.jpg',
		imageAlt: 'Person fishing.',
	},
]

export const FOOD_DRINKS: SwiperProps['items'] = [
	{
		header: 'Daily Catch',
		subHeader: 'Seafood',
		description:
			"Experience the Maldives' vibrant fishing heritage, where the daily catch of fresh tuna and seafood showcases the nation's rich maritime tradition and sustainable practices.",
		imageSrc: '/img/landing/fd-1.jpg',
		imageAlt: 'Fish being prepared for cooking.',
	},
	{
		header: 'Bars',
		subHeader: 'Poolside',
		description:
			'Unwind at the bars, where tropical cocktails, vibrant vibes, and stunning sunset views create the perfect island escape.',
		imageSrc: '/img/landing/fd-2.jpg',
		imageAlt: "Woman at a poolside bar in the Maldives' tropical setting.",
	},
	{
		header: 'Beach BBQ',
		subHeader: 'Grill',
		description:
			'Enjoy the ultimate beachside experience with a BBQ grill feast, where smoky flavors meet the ocean breeze under the starlit Maldivian sky.',
		imageSrc: '/img/landing/fd-3.jpg',
		imageAlt: 'BBQ food being served on the beach.',
	},
	{
		header: 'Fine Dining',
		subHeader: 'Culinary',
		description:
			'Savor the ultimate culinary experience with fine dining in the Maldives, where world-class chefs craft exquisite dishes amidst breathtaking ocean views.',
		imageSrc: '/img/landing/fd-4.jpg',
		imageAlt: 'Fine dining restaurant.',
	},
	{
		header: 'Ethical Dining',
		subHeader: 'Sustainable',
		description:
			'Indulge in ethical dining, where every dish is crafted with fresh, sustainably sourced, fruits and seafood - a guilt-free experience with zero food miles.',
		imageSrc: '/img/landing/fd-5.jpg',
		imageAlt: "People kayaking in the Maldives' turquoise waters.",
	},
	{
		header: 'All Inclusive',
		subHeader: 'Immersive',
		description:
			'Experience the convenience of an all-inclusive package, offering breakfast, lunch, dinner, and drinks with exceptional flavors and stunning views.',
		imageSrc: '/img/landing/fd-6.jpg',
		imageAlt: 'Pool near the beach with a table with drinks on it.',
	},
]
